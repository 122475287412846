import React from "react";
import LogoFooter from "../public/assets/infocenter.png";
export default function Footer() {
  return (
    <footer className="bg-[#555859] text-white pt-10">
      <div className="container mx-auto px-6">
        <div>
          <span className="font-bold text-2xl">ให้เราได้ช่วยเหลือคุณ</span>
        </div>
        <div>
          <span className=" text-lg">ติดต่อเราได้ง่าย หลายช่องทางสะดวก</span>
        </div>
      </div>
      <div className="container mx-auto px-12 mt-8">
        <div className="grid grid-cols-3 ">
          {/*  */}
          <div>
            <h3 className="text-yellow-500 mb-2 text-2xl">ที่อยู่</h3>
            <div className="hover:underline">
              บริษัท โทรคมนาคมแห่งชาติ จำกัด (มหาชน) เลขที่ 99 ถนนแจ้งวัฒนะ
              แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10210
            </div>
            <h3 className="text-yellow-500 mt-6 mb-2 text-2xl">
              รับแจ้งเบาะแสทุจริต
            </h3>
            <div>
              <a
                href="https://www.ntplc.co.th/contact-us/no-corruption"
                className="hover:underline"
              >
                <div>ช่องทางการรับเรื่องร้องเรียนทุจริตฯ</div>
                <div>เว็บไซต์รับเรื่องร้องเรียนทุจริตฯ</div>
              </a>
            </div>
          </div>

          {/*  */}
          <div>
            <h3 className="text-yellow-500 mb-2 text-2xl">
              ติดต่อสินค้าและบริการ
            </h3>
            <div>
              <span>Email : </span>
              <a
                href="mailto:​casupport@ntplc.co.th"
                className="hover:underline"
              >
                ​casupport@ntplc.co.th
              </a>
            </div>
            <div>
              <span>Call Center : </span>
              <a href="tel:1888" className="hover:underline">
                1888
              </a>
            </div>

            <h3 className="text-yellow-500 mt-6 mb-2 text-2xl">
              เสนอแนะข้อคิดเห็น
            </h3>
            <div>
              <a href="mailto:webmaster@ntpl.co.th" className="hover:underline">
                webmaster@ntpl.co.th
              </a>
            </div>
            <div>
              <span>สารบรรณกลาง : </span>
              <a href="mailto:saraban@ntpl.co.th" className="hover:underline">
                saraban@ntpl.co.th
              </a>
            </div>
          </div>

          {/* */}
          <div>
            <h3 className="text-yellow-500 mb-2 text-2xl">
              ติดต่อเรื่องร้องเรียน
            </h3>
            <div>
              <a
                href="https://www.ntplc.co.th/contact-us/complaintchannel"
                className="hover:underline"
              >
                <div>ช่องทางการร้องเรียน</div>
                <div>เว็บไซต์รับเรื่องร้องเรียน</div>
              </a>
            </div>
            <div>
              <span>Email :</span>
              <a
                href="mailto:complaint@ntplc.co.th"
                className="hover:underline"
              >
                complaint@ntplc.co.th
              </a>
            </div>

            <h3 className="text-yellow-500 mt-6 mb-2 text-2xl">
              ติดต่อเรื่องร้องเรียน
            </h3>
            <div>
              <a
                href="https://www.ntplc.co.th/appeal/status"
                className="hover:underline"
              >
                ช่องทางการติดตามสถานะการร้องเรียนของคุณ
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center py-8">
          <div className="text-lg font-semibold">
            NT Contact Center{" "}
            <a href="tel:1888" className="text-yellow-500">
              1888
            </a>
          </div>

          <div className="text-right">
            <a
              href="http://www.oic.go.th/INFOCENTER79/7943/"
              target="_blank"
              className="inline-block mb-4"
            >
              <img
                width="300"
                height="65"
                className="footer-info"
                src={LogoFooter}
                alt="info center"
              />
            </a>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30986.01668215631!2d100.55895356224404!3d13.883869537381939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e28322f2d53e91%3A0xaf2d9416d7d3f05b!2z4Lia4Lih4LiILiDguYLguJfguKPguITguKHguJnguLLguITguKHguYHguKvguYjguIfguIrguLLguJXguLQ!5e0!3m2!1sth!2sth!4v1733038392536!5m2!1sth!2sth"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    </footer>
  );
}
