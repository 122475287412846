import React from "react";
import LogoNT from "../public/assets/img/National_Telecom_Logo.png";
import Logo from "../public/assets/img/NTLogo.png";
import { TbFileCertificate } from "react-icons/tb";

function Document() {
  const handleDownloadCP = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_cp.pdf"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_cp.pdf"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadCPS = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_cps.pdf"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_cps.pdf"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadG2 = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_g2.crt"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_g2.crt"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadG3 = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_g3.crt"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_g3.crt"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadG2CRL = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_g3.crl"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_g3.crl"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadG3CRL = () => {
    const link = document.createElement("a");
    link.href = "/documents/ntca_g3.crl"; // ชื่อไฟล์ที่อยู่ในโฟลเดอร์ public
    link.download = "ntca_g3.crl"; // ชื่อไฟล์ที่จะใช้ตอนดาวน์โหลด
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="pt-20 pb-4 bg-white">
      <div className="min-h-[600px]  w-full">
        <div className="flex justify-center ">
          <span className="text-4xl text-black border-b-2 border-yellow-300">
            เอกสารเผยแพร่ | แนวนโยบาย
          </span>
        </div>
        <div className="grid grid-cols-2 gap-x-6 mt-8 px-6">
          <div className="col-span-1 grid grid-cols-2">
            <div className="col-sapn-1 flex justify-center">
              <div
                className="col-sapn-1 h-[400px] w-[300px] bg-white border border-yellow-100  shadow-lg shadow-yellow-200 cursor-pointer"
                onClick={handleDownloadCP}
              >
                <div className="flex justify-center items-center h-1/2">
                  <img src={LogoNT} alt="logoNT" width="150" height="100" />
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-black text-center">
                    NT Certificate Authority <br />
                    Certificate Policy
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sapn-1 flex items-center">
              <span className="text-black ">
                แนวนโยบายของผู้ให้บริการออก <br />
                ใบรับรองอิเล็กทรอนิกส์ NT CA <br />
                (CP Version 1.0) <br />
                (SSL Baseline)
              </span>
            </div>
          </div>
          <div className="col-span-1 grid grid-cols-2">
            <div className="col-sapn-1 flex justify-center">
              <div
                className="h-[400px] w-[300px] bg-white border border-yellow-100  shadow-lg shadow-yellow-200 cursor-pointer"
                onClick={handleDownloadCPS}
              >
                <div className="flex justify-center items-center h-1/2 ">
                  <img src={LogoNT} alt="logoNT" width="150" height="100" />
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-black text-center">
                    NT Certificate Authority <br />
                    Certification Practice Statement
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sapn-1 flex items-center">
              <span className="text-black">
                แนวปฏิบัติของผู้ให้บริการออก <br />
                ใบรับรองอิเล็กทรอนิกส์ NT CA <br />
                (CP Version 1.0) <br />
                (SSL Baseline)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <hr className="w-11/12" />
      </div>

      {/* cert */}
      <div className="min-h-[450px] w-full mt-12">
        <div className="flex justify-center">
          <span className="text-4xl text-black border-b-2 border-yellow-300">
            รายการใบรับรองอิเล็กทรอนิกส์
          </span>
        </div>

        <div className="mt-8 grid grid-cols-2 gap-4 px-8 text-black">
          <div className="col-span-1 ">
            <div className="text-center">
              <span>
                ผู้ให้บริการออกใบรับรองอิเล็กทรอนิกส์ NT CA (NT Certification
                Authority – G2)
              </span>
            </div>
            <div className=" grid grid-cols-4 text-center">
              <div className="border py-3 px-6 bg-yellow-500">ผู้ให้บริการ</div>
              <div className="border py-3 px-6 bg-yellow-500">
                วันเริ่มใช่งาน
              </div>
              <div className="border py-3 px-6 bg-yellow-500">วันหมดอายุ</div>
              <div className="border py-3 px-6 bg-yellow-500">ดาวน์โหลด</div>
            </div>
            <div className=" grid grid-cols-4  text-center">
              <div className="border h-[100px] flex justify-center items-center">
                <img src={Logo} alt="logo" width="100" />
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                01 พฤษจิกายน 2567
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                01 พฤษจิกายน 2587
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                <div
                  onClick={handleDownloadG2}
                  className="hover:cursor-pointer"
                >
                  <TbFileCertificate className="w-[35px] h-[35px]" />
                </div>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>Thumbprint Algorithm : SHA1</span>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>
                  Thumbprint : 15faa6e924fb02b9e12af12b640eb8a730d6597f
                </span>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>
                  Subject key identifier :
                  17b1fe8741efebcaa35d9abcabaecf4f1c66a976
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-1 ">
            <div className="text-center">
              <span>
                ผู้ให้บริการออกใบรับรองอิเล็กทรอนิกส์ NT CA (NT Certification
                Authority – G3)
              </span>
            </div>
            <div className=" grid grid-cols-4 text-center">
              <div className="border py-3 px-6 bg-yellow-500">ผู้ให้บริการ</div>
              <div className="border py-3 px-6 bg-yellow-500">
                วันเริ่มใช่งาน
              </div>
              <div className="border py-3 px-6 bg-yellow-500">วันหมดอายุ</div>
              <div className="border py-3 px-6 bg-yellow-500">ดาวน์โหลด</div>
            </div>
            <div className=" grid grid-cols-4  text-center">
              <div className="border h-[100px] flex justify-center items-center">
                <img src={Logo} alt="logo" width="100" />
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                01 พฤษจิกายน 2567
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                01 พฤษจิกายน 2587
              </div>
              <div className="border h-[100px] flex justify-center items-center">
                <div
                  onClick={handleDownloadG3}
                  className="hover:cursor-pointer"
                >
                  <TbFileCertificate className="w-[35px] h-[35px]" />
                </div>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>Thumbprint Algorithm : SHA1</span>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>
                  Thumbprint : adb5a7ba9f9ed6300ed7e3fe31aefcfeda9f33dd
                </span>
              </div>
            </div>
            <div className=" grid grid-cols-4">
              <div className="col-span-4 border">
                <span>
                  Subject key identifier :
                  2bcecde5948749a568193932c1c008c30c9285a6
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <span className="text-black mx-0.5">
            รายการใบรับรองที่ยกเลิก (CRL)
          </span>
          <span
            className="text-blue-600 hover:cursor-pointer ml-0.5"
            onClick={handleDownloadG2CRL}
          >
            NT Certification Authorty - G2
          </span>
          <span className="text-black mr-0.5">, </span>
          <span
            className="text-blue-600 hover:cursor-pointer mx-0.5"
            onClick={handleDownloadG3CRL}
          >
            NT Certification Authority - G3
          </span>
        </div>
      </div>
      <div className="px-4 ">
        <span className="text-red-500 italic">
          *หมายเหตุ ข้อมูลนี้เป็นเพียงข้อมูลสำหรับทดสอบการให้บริการ
          ยังไม่ใช่ข้อมูลจริงสำหรับบริการ NT CA
        </span>
      </div>
    </div>
  );
}

export default Document;
