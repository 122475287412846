import NTlogo from "../public/assets/img/NTLogo.png";
import Flow from "../public/assets/img/flow.jpg";
import CA from "../public/assets/img/CA.jpg";
import Ntca1 from "../public/assets/img/ntca/ntca-1.jpg";
import Ntca2 from "../public/assets/img/ntca/ntca-2.jpg";
import Ntca3 from "../public/assets/img/ntca/ntca-3.png";

export default function Home() {
  return (
    <div>
      <section
        id="ntca-home"
        className="min-h-[800px] flex justify-center items-center "
      >
        <div>
          <img
            src={NTlogo}
            width="130"
            height="90"
            alt="logo"
            className="mb-4"
          />
          <span className="text-2xl">
            NT CA(บริการออกใบรับรองอิเล็กทรอนิกส์ Certificate Authority)
          </span>
        </div>
      </section>
      <section
        id="ntca-partner"
        className="relative h-[900px] flex justify-center items-center -z-10"
      >
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-white to-white clip-skew "></div>
        <div className="relative text-black flex justify-center">
          <img
            src={CA}
            width="1280"
            height="720"
            alt="logo"
            className="shadow-md bg-red rounded-xl  p-4 m-4 shadow-yellow-400"
          />
        </div>
      </section>
      <section
        id="ntca-partner"
        className="relative h-[900px] flex justify-center items-center -z-10"
      >
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-white to-white clip-skew "></div>
        <div className="relative text-black ">
          <div className="flex justify-center">
            <span className="text-3xl font-bold text-yellow-500">
              Procedure for certificate
            </span>
          </div>
          <div className="">
            <img
              src={Flow}
              alt={`flow`}
              width="450"
              height="300"
              className="shadow-md bg-red rounded-xl  p-4 m-4 shadow-yellow-400 "
            />
          </div>
        </div>
      </section>

      <section
        id="ntca-ntca"
        className="relative h-[900px] flex justify-center items-center -z-10"
      >
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-white to-white clip-skew "></div>
        <div className="relative text-black ">
          <div className="flex justify-center">
            <span className="text-3xl font-bold text-yellow-500">NT CA</span>
          </div>
          <div className="grid grid-cols-2 gap-x-2  mt-12 px-48">
            <div>
              <div>
                <img src={Ntca1} alt="Ntca1" width="282" height="158" />
              </div>
              <div>
                <img src={Ntca2} alt="Ntca2" width="282" height="158" />
              </div>
              <div>
                <img src={Ntca3} alt="Ntca3" width="282" height="158" />
              </div>
            </div>
            <div>
              <div>
                <div>Internet Data Center</div>
                <div>CloudApps ได้รับใบรับรองมาตรฐาน ISO/IEC 27001</div>
              </div>
              <div className="mt-12">
                <div>อาคารของศูนย์ IDC</div>
                <div>
                  ตัวอาคารสามารถรองรับน้ำหนักต่อตู้กว่า 1,000 กก. เป็นอาคารด้าน
                  IT และงานด้านการสื่อสารโดยเฉพาะ
                </div>
              </div>
              <div className="mt-12">
                <div>ระบบไฟฟ้า หม้อแปลงไฟฟ้า</div>
                <div>
                  เครื่องกำเนิดไฟฟ้า (Generator)
                  ออกแบบเพื่อการทำงานแบบความต่อเนื่องของการจ่ายไฟฟ้าและเสถียรภาพ
                </div>
              </div>
              <div className="mt-12">
                <div>ระบบปรับอากาศ ศูนย์ IDC</div>
                <div>
                  เครื่องปรับอากาศที่ควบคุมอุณหภูมิและความชื้นทำงานแบบ N+1
                  เป็นระบบปรับอากาศสำหรับศูนย์ Data Center
                </div>
              </div>
              <div className="mt-12">
                <div>ระบบรักษาความปลอดภัย</div>
                <div>
                  เราใช้มาตรการรักษาความปลอดภัยเข้าออก
                  ต้องผ่านเจ้าหน้าที่รักษาความปลอดภัยและระบบ Finger Scan
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
