import React from "react";
import PolicyPhoto from "../public/assets/img/policy.jpg";

function PolicyPage() {
  return (
    <div className="flex justify-center pt-20 pb-4">
      <img
        src={PolicyPhoto}
        alt="photo policy"
        className="object-cover"
        height="800"
      />
    </div>
  );
}

export default PolicyPage;
