import React from "react";
import LogoFullName from "../public/assets/img/NTLogowithFullName.png";
import { Link, Outlet } from "react-router";
import Footer from "./Footer";

function Navbar() {
  return (
    <div>
      <nav className="py-4 max-h-16 flex justify-around px-3 fixed w-full bg-white items-center z-50">
        <div>
          <img width="287" height="55" src={LogoFullName} alt="logo fullname" />
        </div>
        <ul className="flex space-x-6 h-16 items-center text-black">
          <Link to={"/home"}>
            <li>หน้าหลัก</li>
          </Link>
          <Link to={"/document"}>
            <li>เอกสารเผยแพร่</li>
          </Link>
          <Link to={"/policy"}>
            <li>นโยบายคุ้มครองข้อมูลส่วนบุคคล</li>
          </Link>
        </ul>
      </nav>
      <div className="pt-16">
        <Outlet />
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Navbar;
