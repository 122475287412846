import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Document from './page/Document';
import Home from './page/Home';
import PolicyPage from './page/Policy';

function App() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Navbar />,
        children: [
          {
            path: "/",
            element: <Navigate to="/home" replace />, // Redirect to /home
          },
          {
            path: "home",
            element: (
              <Home />
            ),
          },
          {
            path: "document",
            element: (
              <Document />
            ),
          },
          {
            path: "policy",
            element: (
              <PolicyPage />
            ),
          },
        ]
      },
    ],

  );
  return <RouterProvider router={router} />;
}

export default App;
